/* Dark Theme Colors */
/* Light Theme Colors */
/* Org Theme Colors */
/* Light Theme Colors */
#orange {
  /* Start Style Layout */
  /* End Style Layout */
  /* Start Style Header */
  /* End Style Header */
  /* Start Style Home */
  /* End Style Home */
  /* Start Style Loader */
  /* Start Style Loader */
}

#orange * {
  color: #000;
}

#orange body {
  background: #c98126;
}

#orange header {
  /*TODO:Add LottieFile To Button For More Butty */
}

#orange header .btn-toggle button:hover span:nth-child(2) {
  border: 1px solid #fff;
}

#orange header .btn-toggle button span {
  background: #fff;
}

#orange header .d-header .links,
#orange header .d-header .logo {
  background: #c98126;
  -webkit-box-shadow: 0 0.5em 1em #00000061;
          box-shadow: 0 0.5em 1em #00000061;
}

#orange header .d-header .logo::after, #orange header .d-header .logo::before {
  background: #c98126;
}

#orange header .d-header ul li:hover,
#orange header .d-header .btn-group a:hover {
  background: #f1af5d;
}

#orange header .d-header ul li a img {
  -webkit-filter: invert(1);
          filter: invert(1);
}

#orange .home {
  /* End Style Home */
}

#orange .home .container .social-media li {
  background: #c98126;
}

#orange .home .container .social-media li:last-child {
  background: #c98126;
}

#orange .home .container .social-media .heading h1 {
  text-shadow: 0 5px 10px #00000061;
}

#orange .home .container .social-media .heading p {
  text-shadow: 0 4px 2px #00000061;
}

#orange .home .container .social-media .box {
  border: 0.1em solid #c98126;
}

#orange .home .container .social-media .box .cards .cards-box .card {
  background: #c98126;
}

#orange .home .container .heading h1 {
  color: #c98126;
}

#orange .home .container .heading p {
  color: #fff;
}

#orange .home .container .box {
  border-color: #c98126;
}

#orange .home .container .box .cards h3,
#orange .home .container .box .cards span,
#orange .home .container .box .cards h6 {
  color: #c98126;
}

#orange .home .container .box .cards span {
  font-weight: 400;
}

#orange #Loader {
  background: #c98126;
}
