#setting {
    padding: 1em;
    background-color: $dark-main-color;
    position: fixed;
    top: 30%;
    left: 0;
    z-index: 9999999;
    max-height: 36em;
    direction: ltr;
    left: -125px;
    transition: left 1s ease;
    cursor: pointer;

    &:hover {
        left: 0;
    }
    h4 {
        font-size: 1.5em;
        font-weight: 300;
        letter-spacing: 0.15em;
        margin-bottom: 0.3em;
    }
    h6 {
        margin-bottom: 0.5em;
        font-size: 1.5em;
        font-weight: 300;
    }
    span.hr {
        width: 100%;
        height: 0.1em;
        background: $dark-seconde-color;
        display: block;
        margin-bottom: 0.5em;
    }
    h4,
    h6,
    span.hr {
        color: #fff;
    }
    .op {
        padding: 0.2em;
        margin: 0.7em 0;
    }

    button {
        &:first-child {
            margin-right: 1em;
        }
        &.active {
            background: $dark-hover-color;
        }
        cursor: pointer;
        padding: 0.1em 1em;
        color: $dark-seconde-color;
        background: $dark-main-color;
        border: 1px solid $dark-seconde-color;
    }
    /* Start Style Theme Setting */
    ul {
        list-style: none;
        li {
            padding: 1em;
            margin-top: 1em;
            cursor: pointer;
            text-transform: uppercase;
            box-shadow: 0.5px 0.5px 0px rgb(255, 255, 255);
            font-weight: 600;
            letter-spacing: 0.3em;

            &:nth-child(1) {
                background: linear-gradient(
                    45deg,
                    $dark-main-color 33%,
                    $social-color 33%,
                    66%,
                    $dark-seconde-color 66%
                );
                color: $dark-seconde-color;
            }
            &:nth-child(2) {
                background: linear-gradient(
                    45deg,
                    $light-main-color 33%,
                    $light-white-color 33%,
                    66%,
                    $light-seconde-color 66%
                );
                color: $light-seconde-color;
            }
            &:nth-child(3) {
                background: linear-gradient(
                    45deg,
                    $org-main-color 33%,
                    $org-white-color 33%,
                    66%,
                    $org-seconde-color 66%
                );
                color: $org-seconde-color;
            }
            &:nth-child(4) {
                background: linear-gradient(
                    45deg,
                    $blue-main-color 33%,
                    $blue-white-color 33%,
                    66%,
                    $blue-social-color 66%
                );
                color: $blue-seconde-color;
            }
        }
    }
}
