@import "./../SASS/colors";
#darkBlue {
    /* Start Style Layout */
    * {
        color: $blue-seconde-color;
    }
    body {
        background: $blue-main-color;
    }

    /* End Style Layout */
    /* Start Style Header */
    header {
        /*TODO:Add LottieFile To Button For More Butty */
        .btn-toggle {
            button {
                &:hover {
                    span {
                        &:nth-child(2) {
                            border: 1px solid $blue-white-color-01;
                        }
                    }
                }
                span {
                    background: $blue-white-color-01;
                }
            }
        }
        .d-header {
            .links,
            .logo {
                background: $blue-main-color;
                box-shadow: 0 0.5em 1em $blue-shadow-color;
            }
            .logo {
                &::after,
                &::before {
                    background: $blue-main-color;
                }
            }

            ul li,
            .btn-group a {
                &:hover {
                    background: $blue-hover-color;
                }
            }
            ul {
                li {
                    a {
                        img {
                            filter: invert(0);
                        }
                    }
                }
            }
        }
    }
    /* End Style Header */
    /* Start Style Home */
    .home {
        .container {
            .social-media {
                &::before {
                    background: $blue-main-color;
                }
                li {
                    // border: 0.2em solid $blue-main-color;

                    background: $blue-main-color;
                    img {
                        filter: invert(1);
                    }
                    &:last-child {
                        background: $blue-social-color;
                    }
                }
                .heading {
                    h1 {
                        text-shadow: 0 5px 10px $blue-shadow-color;
                    }
                    p {
                        text-shadow: 0 4px 2px $blue-shadow-color;
                    }
                }
                .box {
                    border: 0.1em solid $blue-main-color;

                    .cards {
                        .cards-box {
                            .card {
                                background: $blue-main-color;
                            }
                        }
                    }
                }
            }
            .heading {
                h1 {
                    color: $blue-white-color;
                }

                p {
                    color: $blue-white-color-01;
                }
            }
            .box {
                border-color: $blue-main-color;
                .cards {
                    h3,
                    span,
                    h6 {
                        color: $blue-white-color;
                    }
                    span {
                        font-weight: 400;
                    }
                }
            }
        }

        /* End Style Home */
    }
    /* End Style Home */
    /* Start Style Loader */
    #Loader {
        background: $blue-main-color;
    }
    /* Start Style Loader */
}
