.container {
    margin: 0 auto;
    padding: 0 12px;
}

/* Bootstrap Breakpoints */
/* 
============
==== Min ==== 
============
*/

// Extra Small devices (landscape phones, 200px and up)
@media (min-width: 300px) {
    /* Start  Layout */
    .container {
        max-width: 100%;
    }
    body {
        font-size: $m-font-size;
    }
    /* End  Layout */
}

// Small devices (landscape phones, 576px and up)

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
    body {
        font-size: $s-font-size;
    }
}

// Large devices (desktops, 992px and up)
@media (max-width: 991px) {
    header {
        max-height: 50px;
        overflow: hidden;
        .btn-toggle {
            display: inline;
        }
        background: $dark-main-color;
        .d-header {
            justify-content: space-between;
            flex-direction: column;
            .links,
            .logo {
                background: none;
                box-shadow: none;
            }
            .links {
                order: 3;
                flex-direction: column;
                ul {
                    flex-direction: column;
                }
            }
            .logo {
                order: 0;
                &::after,
                &::before {
                    position: static;
                }
            }
        }
    }
}
@media (min-width: 575.99px) {
    /* Start Event Style  */

    .Event {
        padding-top: 5em;
        .container {
            .Event-slideshow {
                .Event-slideshowSlider {
                    .slideshowSlider_slide {
                        justify-content: center;
                        .slide_img {
                            margin-bottom: 5em;
                            h3 {
                                font-size: 5em;
                                bottom: -15px;
                                left: -45px;
                            }
                        }
                        .slide_text-content {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            p {
                                text-align: justify;
                                max-width: 485px;
                            }
                            button {
                                margin-top: 2em;
                                position: static;
                            }
                        }
                    }
                }
            }
        }
    }
    /* End Event Style  */
}
@media (min-width: 992px) {
    .container {
        max-width: 800px;
    }
    /* Start Event Style  */

    .Event {
        .container {
            .Event-slideshow {
                .Event-slideshowSlider {
                    .slideshowSlider_slide {
                        flex-direction: row;
                        flex-wrap: nowrap;
                        .slide_img {
                            max-width: 350px;
                            h3 {
                                bottom: -18px;
                            }
                        }
                        .slide_text-content {
                            align-items: flex-start;
                            p {
                                text-align: justify;
                                max-width: 485px;
                            }
                            button {
                                margin-top: 2em;
                                position: static;
                                font-size: 1.5em;
                            }
                        }
                    }
                }
            }
        }
    }
    /* End Event Style  */
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1024px) {
    body {
        font-size: $m-font-size;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
    body {
        font-size: $m-font-size;
    }
}
@media (min-width: 1300px) {
    body {
        font-size: $l-font-size;
    }
    header {
        a {
            span {
                font-size: 1em;
            }
        }
    }
    .Event {
        padding-top: 150px;
        .container {
            .Event-slideshow {
                .Event-slideshowSlider {
                    .slideshowSlider_slide {
                        .slide_img {
                            max-width: 550px;
                            h3 {
                                left: -50px;
                            }
                        }
                    }
                }
            }
        }
    }
}
// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
    .container {
        max-width: 1320px;
    }
    body {
        font-size: $d-font-size;
    }
}
@media (min-width: 1500px) {
    .container {
        max-width: 1320px;
    }
}
@media (min-width: 1600px) {
}

/*
=============
==== Max ==== 
=============
*/
// X-Small devices (portrait phones, less than 576px)

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .home {
        .container {
            .social-media {
                min-height: 40%;
                top: 10%;
            }
            .heading {
                p {
                    max-width: 80%;
                }
            }
            .box {
                width: 100%;
            }
        }
    }
}
@media (max-width: 575.98px) {
    /* Start Home Style  */
    .home {
        padding-top: 15em;
        height: auto;
        padding-bottom: 5em;
        .container {
            row-gap: 5em;
            justify-content: flex-start;
            // padding-top: 50px;
            padding-bottom: 5px;
            .heading {
                padding: 3em;
                backdrop-filter: blur(10px);
                border: 0.1em solid $dark-main-color;
                p {
                    font-weight: 600;
                }
                h1,
                p {
                    text-shadow: none;
                    color: $dark-main-color;
                }
            }

            .box {
                position: relative;

                .cards {
                    flex-direction: column;
                    row-gap: 3em;
                    padding: 3em;
                    .cards-box {
                        flex-direction: column;
                        row-gap: 3em;
                        width: 100%;
                        .card {
                        }
                    }
                }
            }
        }
    }
    /* End Home Style  */
    /* Start Event Style  */
    .Event {
        padding-top: 5em;
        .container {
            .Event-slideshow {
                .Event-slideshowSlider {
                    .slideshowSlider_slide {
                        justify-content: center;
                        padding: 0 15px;
                        .slide_img {
                            margin-bottom: 5em;
                            margin-left: 35px;
                            &::before {
                                left: -20px;
                                top: 0;
                            }
                            h3 {
                                font-size: 3em;
                                bottom: -12px;
                                left: -20px;
                            }
                        }
                        .slide_text-content {
                            p {
                                text-align: justify;
                            }
                            button {
                                margin-top: 2em;
                                position: static;
                            }
                        }
                    }
                }
            }
        }
    }
    /* End Event Style  */
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {
}

// XX-Large devices (larger desktops)
// No media query since the xxl breakpoint has no upper bound on its width
