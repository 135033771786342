/* Dark Theme Colors */
$dark-main-color: #070b0e;
$dark-seconde-color: #fff;
$social-color: #5a6e7f;
$dark-white-color: #ffffff33;
$dark-shadow-color: #000000a1;
$dark-hover-color: #ffffff10;
$dark-border-color: #444342;
/* Light Theme Colors */
$light-main-color: #f1f3f6;
$light-seconde-color: #070b0e;
$light-social-color: #f1f3f6;

$light-shadow-color: #f1f3f67c;
$light-white-color-01: #eee;
$light-white-color: #ffffff33;
$light-shadow-color: #000000a1;
$light-hover-color: #00000018;
/* Org Theme Colors */
$org-main-color: #c98126;
$org-seconde-color: #000;
$org-white-color-01: #fff;
$org-social-color: #c98126;

$org-shadow-color: #f1f3f67c;
$org-white-color: #ffffff33;
$org-shadow-color: #00000061;
$org-hover-color: #f1af5d;

/* Light Theme Colors */
$blue-main-color: #22294e;
$blue-seconde-color: #f1f2f6;
$blue-social-color: #0a0f27;

$blue-shadow-color: #0a0f272f;
$blue-white-color-01: #ffffff;
$blue-white-color: #869aff;
$blue-shadow-color: #000000a1;
$blue-hover-color: #ffffff10;
