.home {
    background: url("./assets/images/png/sean-pollock.png") bottom no-repeat;

    background-size: cover;
    background-attachment: fixed;
    width: 100vw;
    height: 100vh;
    // backdrop-filter: blur(10px);
    .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        .social-media {
            position: absolute;
            right: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            list-style: none;
            min-height: 60%;
            z-index: 999;
            animation-delay: 1.5s;

            &::before {
                position: absolute;
                content: "";
                width: 0.17em;
                height: 100%;
                background: $dark-main-color;
                z-index: -99999;
            }
            li {
                width: 4em;
                height: 4em;
                border: 0.2em solid $dark-main-color;
                padding: 0.5em;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $social-color;
                animation-delay: 2.3s;
                cursor: pointer;
                transition: transform 0.4s ease;
                &:hover {
                    transform: scale(0.95);
                }
                &:not(:last-child) {
                    margin-top: 1.5em;
                }
                &:first-child {
                    margin-top: 6em;
                    a {
                        img {
                            width: 1.3em;
                        }
                    }
                }
                &:last-child {
                    position: absolute;
                    bottom: -25px;
                    background: $dark-white-color;
                    backdrop-filter: blur(3px);
                    transform: translateY(0px);

                    &:hover {
                        a {
                            img {
                                animation: UpDown 1s ease infinite alternate;
                            }
                        }
                    }
                }
                a {
                    img {
                        width: 1.9em;
                    }
                }
            }
        }
        .heading {
            h1 {
                font-size: 4em;
                text-shadow: 0 5px 10px $dark-shadow-color;
                margin-bottom: 0.5em;
                letter-spacing: 0.1em;
                animation-delay: 1s;
            }
            p {
                font-size: 2em;
                max-width: 50%;
                line-height: 1.6;
                margin-bottom: 1em;
                text-shadow: 0 4px 2px $dark-shadow-color;
                animation-delay: 1.15s;
            }
        }
        .box {
            width: 60%;
            border: 0.1em solid $dark-main-color;
            justify-self: flex-end;
            position: absolute;
            bottom: 2%;
            animation-delay: 1.3s;
            // backdrop-filter: blur(10px);
            backdrop-filter: blur(2px);
            .cards {
                animation-delay: 1.15s;
                padding: 2em 3em;
                display: flex;
                justify-content: space-between;
                align-items: center;
                column-gap: 2em;
                position: relative;
                overflow: hidden;
                .overlay-blur {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.596);
                    filter: blur(50px);
                    z-index: -9;
                }
                h3 {
                    align-self: flex-start;
                    letter-spacing: 0.2em;
                    font-size: 2em;
                    max-width: 300px;
                    line-height: 1.7;
                    animation-delay: 1.35s;
                    font-style: italic;
                }
                .cards-box {
                    display: flex;
                    column-gap: 3em;
                    justify-self: flex-end;

                    .card {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        row-gap: 0.5em;
                        background: $dark-main-color;
                        padding: 1em 1.5em;
                        animation-delay: 1.15s;

                        img {
                            animation-delay: 1.3s;
                        }
                        h6 {
                            font-size: 1.5em;
                            letter-spacing: 0.1em;
                            font-weight: 600;
                            margin-bottom: 0.5em;
                            animation-delay: 1.8s;
                        }
                        span {
                            text-align: center;
                            margin-bottom: 1em;
                            font-size: 1.1em;
                            font-weight: 400;
                            letter-spacing: 0.08em;
                            animation-delay: 2s;
                        }
                    }
                }
            }
        }
    }
}
