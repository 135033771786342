@import "./../SASS/colors";
#orange {
    /* Start Style Layout */
    * {
        color: $org-seconde-color;
    }
    body {
        background: $org-main-color;
    }

    /* End Style Layout */
    /* Start Style Header */
    header {
        /*TODO:Add LottieFile To Button For More Butty */
        .btn-toggle {
            button {
                &:hover {
                    span {
                        &:nth-child(2) {
                            border: 1px solid $org-white-color-01;
                        }
                    }
                }
                span {
                    background: $org-white-color-01;
                }
            }
        }
        .d-header {
            .links,
            .logo {
                background: $org-main-color;
                box-shadow: 0 0.5em 1em $org-shadow-color;
            }
            .logo {
                &::after,
                &::before {
                    background: $org-main-color;
                }
            }

            ul li,
            .btn-group a {
                &:hover {
                    background: $org-hover-color;
                }
            }
            ul {
                li {
                    a {
                        img {
                            filter: invert(1);
                        }
                    }
                }
            }
        }
    }
    /* End Style Header */
    /* Start Style Home */
    .home {
        .container {
            .social-media {
                &::before {
                    // background: $org-main-color;
                }
                li {
                    // border: 0.2em solid $org-main-color;

                    background: $org-social-color;
                    img {
                        // filter: invert(1);
                    }
                    &:last-child {
                        background: $org-main-color;
                    }
                }
                .heading {
                    h1 {
                        text-shadow: 0 5px 10px $org-shadow-color;
                    }
                    p {
                        text-shadow: 0 4px 2px $org-shadow-color;
                    }
                }
                .box {
                    border: 0.1em solid $org-main-color;

                    .cards {
                        .cards-box {
                            .card {
                                background: $org-main-color;
                            }
                        }
                    }
                }
            }
            .heading {
                h1 {
                    color: $org-main-color;
                }

                p {
                    color: $org-white-color-01;
                }
            }
            .box {
                border-color: $org-main-color;
                .cards {
                    h3,
                    span,
                    h6 {
                        color: $org-main-color;
                    }
                    span {
                        font-weight: 400;
                    }
                }
            }
        }

        /* End Style Home */
    }
    /* End Style Home */
    /* Start Style Loader */
    #Loader {
        background: $org-main-color;
    }
    /* Start Style Loader */
}
