@import "./../SASS/colors";
#dark {
    .Event {
        .container {
            .Event-slideshow {
                .Event-slideshowSlider {
                    .slideshowSlider_slide {
                        .slide_img {
                            h3 {
                                background: $dark-seconde-color;
                                color: $dark-main-color;
                            }
                        }
                    }
                }
            }
        }
    }
}
