/* Dark Theme Colors */
/* Light Theme Colors */
/* Org Theme Colors */
/* Light Theme Colors */
#light {
  /* Start Style Layout */
  /* End Style Layout */
  /* Start Style Header */
  /* End Style Header */
  /* Start Style Home */
  /* End Style Home */
  /* Start Style Loader */
  /* Start Style Loader */
}

#light * {
  color: #070b0e;
}

#light body {
  background: #f1f3f6;
}

#light header {
  /*TODO:Add LottieFile To Button For More Butty */
}

#light header .btn-toggle button:hover span:nth-child(2) {
  border: 1px solid #eee;
}

#light header .btn-toggle button span {
  background: #eee;
}

#light header .d-header .links,
#light header .d-header .logo {
  background: #f1f3f6;
  -webkit-box-shadow: 0 0.5em 1em #000000a1;
          box-shadow: 0 0.5em 1em #000000a1;
}

#light header .d-header .logo::after, #light header .d-header .logo::before {
  background: #f1f3f6;
  -webkit-box-shadow: 0 0 10px #000000a1;
          box-shadow: 0 0 10px #000000a1;
}

#light header .d-header ul li:hover,
#light header .d-header .btn-group a:hover {
  background: #00000018;
}

#light header .d-header ul li a img {
  -webkit-filter: invert(1);
          filter: invert(1);
}

#light .home {
  /* End Style Home */
}

#light .home .container .social-media li {
  background: #f1f3f6;
}

#light .home .container .social-media li:last-child {
  background: #f1f3f6;
}

#light .home .container .social-media .heading h1 {
  text-shadow: 0 5px 10px #000000a1;
}

#light .home .container .social-media .heading p {
  text-shadow: 0 4px 2px #000000a1;
}

#light .home .container .social-media .box {
  border: 0.1em solid #f1f3f6;
}

#light .home .container .social-media .box .cards .cards-box .card {
  background: #f1f3f6;
}

#light .home .container .heading h1 {
  color: #f1f3f6;
}

#light .home .container .heading p {
  color: #eee;
}

#light .home .container .box {
  border-color: #f1f3f6;
}

#light .home .container .box .cards h3,
#light .home .container .box .cards span,
#light .home .container .box .cards h6 {
  color: #f1f3f6;
}

#light .home .container .box .cards span {
  font-weight: 400;
}

#light #Loader {
  background: #f1f3f6;
}
